<template>
    <div>
        <b-container>
            <section class="privacy">
                <h2 class="main-title">Privacy policy</h2>
                <h4 class="custom-subtitle">What information do we collect?</h4>
                <p class="custom-text">We collect information you provide directly to us. For example, we collect information when you create an account, participate in any interactive features of the services, fill out a form, interact with a message board, apply for a job, request customer support, use our platform or otherwise communicate with us. The types of information we may collect include your personal information and any other information you choose to provide. We or a designated third party may collect payment and credit card information when you subscribe to our paid services or purchase additional services.<br><br>
                We protect your personal data in accordance with applicable laws and our data privacy policies. In addition, we maintain the appropriate technical and organizational measures to protect your personal data against unauthorized or unlawful processing and/or against accidental loss, alteration, disclosure or access, or accidental or unlawful destruction of or damage thereto.
                </p>
                <h4 class="custom-subtitle">How do we use your information?</h4>
                <p class="custom-text">We use the information we collect about you to establish your account and provide the services, provide customized content to you, communicate with you through email, send you emails to inform you about our features and services, promotional messages and web analytics data, provide feedback to third party businesses, and take action regarding unlawful or harmful activities, contact you with marketing and advertising that we believe may be of interest to you or provide our products and services you have requested or purchased from us.</p>
                <h4 class="custom-subtitle">Use of external analytics tools</h4>
                <p class="custom-text">We use analytics tools (e.g. Google Analytics) to collect data about the use of the sites and service. Analytics tools collect data such as how often users visit the sites or services, what pages they visit when they do so and what website they came directly from the sites or service. We use the data we get from these tools to maintain and improve the sites and service and our other products.</p>
                <h4 class="custom-subtitle">Security</h4>
                <p class="custom-text">The security of your information is very important to us, but please be aware that no security measures are perfect or impenetrable, and no method of transmission over the Internet, or method of electronic storage, is 100% secure. As a result, while we strive to protect your personal information, we cannot ensure or warrant the security of any information which you send to us, and you do so at your own risk.</p>
                <h4 class="custom-subtitle">Links to other websites</h4>
                <p class="custom-text">As part of the service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This privacy policy applies solely to information collected by us through the service. Therefore, this privacy policy does not apply to your use of a third-party website accessed by selecting a link via our service. To the extent that you access or use the service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.</p>
                <h4 class="custom-subtitle">Age of consent</h4>
                <p class="custom-text">By using the service, you represent that you are at least 18 years of age.</p>
                <h4 class="custom-subtitle">Children's privacy</h4>
                <p class="custom-text">Our website is for everyone but we are not responsible for underage users. If you are underaged please let your guardian know you want to learn more about programming and inform them of your online activities. There are many free things for learning enthusiasts.</p>
                <h4 class="custom-subtitle">Updates to the privacy policy</h4>
                <p class="custom-text">We reserve the right to modify this privacy policy at any time. All changes to this privacy policy will be posted on our website, by email, or by any method we determine.</p>
            </section>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>
